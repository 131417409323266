:root {
  --fc-border-color: #707070;
  --fc-neutral-bg-color: #121212;
  --fc-button-bg-color: transparent;
  --fc-button-border-color: transparent;
}
body {
  margin: 0;
  font-family: 'PoppinsRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@media only screen and (max-width: 1200px) {
  body {
    overflow: scroll;
  }
}

a {
  text-decoration: none;
}
* {
  box-sizing: border-box;
}
.taged_user {
  color: #154698;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* <-----------------------------popins font family-----------------------------------> */
@font-face {
  font-family: 'PoppinsRegular';
  src: local('Poppins-Regular'),
    url('./assets/Fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsMedium';
  src: local('Poppins-Medium'),
    url('./assets/Fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsLight';
  src: local('Poppins-Light'),
    url('./assets/Fonts/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsSemiBold';
  src: local('Poppins-SemiBold'),
    url('./assets/Fonts/Poppins-SemiBold.ttf') format('truetype');
}

/* .grid:after {
  content: ' ';
  flex: auto;
} */

.input {
  display: none;
}

.pac-target-input {
  width: 85% !important;
  left: 4px !important;
  top: 16px !important;
}

.filter-calendar {
  margin-top: 0;
  font-size: 0.8rem;
  font-weight: 400;
  width: 250px;
  height: 40px;
  outline: none;
  border-width: 1px;
  border-style: solid;
  border-color: #154698;
  background-color: transparent;
  padding: 4px 30px 4px 10px;
  color: #0d0d0d;
  opacity: 1;
  border-radius: 7px;
}
.fc-daygrid-event-harness > a {
  border: 0.5px dashed #fff;
  border-color: #fff !important;
}
.mapboxgl-map {
  width: 445px;
  height: 220px;
}
.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.gmnoprint {
  display: none;
}

@media print {
  @page {
    size: auto;
    margin: 0;
    /* margin-left: 50px;
    margin-top: 0.5cm; */
  }
  body {
    /* padding-top: 72px;
    padding-bottom: 72px; */
  }
}
.react-pdf__Page {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
}
.color-picker .github-picker > span > div {
  width: 55px !important;
  height: 55px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge'] {
  display: none;
}
.transform-component-module_wrapper__SPB86 {
  height: 100% !important;
}

fieldset {
  border: none;
  outline: 0;
}
/* Works on Firefox */
/* * {
    scrollbar-width:5px !important;
    scrollbar-color: #D5D5D5 #707070 !important;
   
  } */

/* Works on Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
    width: 5px !important;

  }
  
  *::-webkit-scrollbar-track {
    background: #D5D5D5 !important;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #707070 !important;
    border-radius: 20px !important;
    border: 3px solid #707070 !important;
  }
  *::-webkit-scrollbar-button {
    display:none;
  } */

.react-tel-input .country-list::-webkit-scrollbar {
  display: none;
}

.react-tel-input .country-list .country-name {
  font-family: 'PoppinsRegular';
  font-size: 14px;
}

.react-tel-input .country-list .country .dial-code {
  font-family: 'PoppinsRegular';
  font-size: 13px;
}
.react-pdf__Page__textContent.textLayer {
  display: none;
}
.react-datepicker-wrapper {
  width: 100% !important;
  height: 35px !important;
}

#datepicker {
  width: 100% !important;
  height: 35px !important;
  border-radius: 4px;
  cursor: pointer;
  outline: none !important;
  border: 0.5px solid #ccc;
  padding-left: 10px;
}

.react-pdf__Page {
  background-color: gray !important;
}
/* <----------------------calander----------------------------> */
.fc-col-header {
  width: 100%;
}
.fc .fc-daygrid-day-top {
  justify-content: flex-end;
}
.fc .fc-daygrid-day-frame {
  cursor: pointer;
}
.fc .fc-button-group {
  /* flex-direction: column;
  position: absolute;
  right: 0;
  z-index: 3;
  width: 150px;
  top: 40px;
  background: #000000;
  box-shadow: 0px 3px 15px #00000029;
  border-radius: 14px; */
}
.fc .fc-toolbar.fc-header-toolbar {
  position: relative;
}
.fc .fc-toolbar-title {
  display: none;
}
.react-datepicker__triangle {
  display: none;
}
.transform-component-module_wrapper__SPB86 {
  width: 100% !important;
}
